import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  AccreditationsId,
  AccreditationsRole,
  bcoAccreditations,
  dcoAccreditations,
} from 'app/models/Accreditation';
import { RolesId } from 'app/models/Role';
import User from 'app/models/User';
import { Option } from 'core/components/DropDown';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';
import { MissionFormData } from 'planning/containers/MissionDetailPage/hooks/useMissionSchema';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import useOfficersReducer from 'planning/hooks/useOfficersReducer';
import useRedirectToAgenda from 'planning/hooks/useRedirectToAgenda';
import TeamMember, { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberData } from '../TeamTab/useTeamInputMapping';

import { TopSpacedFlexCell } from './styled';
import { OfficersFormData } from './useOfficersInputMapping';

type EntityFormData = {
  dateRange: TestFormData['dateRange'] | MissionFormData['dateRange'];
} & OfficersFormData;

const dcoReducerOptions = { requiredRoles: [AccreditationsRole.DCO] };
const bcoReducerOptions = { requiredRoles: [AccreditationsRole.BCO] };
const chaperoneReducerOptions = { requiredRoles: [AccreditationsRole.CHAPERONE] };
const CHAPERONE_ACCREDITATION = [AccreditationsId.CHAPERONE];

const OfficersFieldset: FC<{
  hasBlood: boolean;
  limitedAssignmentAllowed: boolean;
  alreadySavedDcosId: number[];
  alreadySavedBcosId: number[];
  alreadySavedChaperonesId: number[];
}> = ({
  hasBlood,
  alreadySavedDcosId,
  alreadySavedBcosId,
  limitedAssignmentAllowed,
  alreadySavedChaperonesId,
}) => {
  const clientId = useAppSelector(
    (state) => (state.core.user && state.core.user.client.id) || null
  );
  const { reload: loadAvailabilityCategories, data: availabilityCategories } =
    useResources<AvailabilityCategory>('profile/availabilities/categories', { autoload: false });

  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<EntityFormData>();
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const { diffMode: isBulkEdit } = useContext(FormContext);
  const canPatchTeam = usePermission(`tests:patch[actions:team]`);

  const officerConditions = {
    clientsId: clientId,
    rolesId: RolesId.DCO,
  };
  const { dateRange, dcos, bcos, chaperones } = values;

  const dateFrom: Date | undefined = (dateRange &&
    (Array.isArray(dateRange) ? dateRange[0] : dateRange.from)) as Date | undefined;
  const dateTo: Date | undefined = (dateRange &&
    (Array.isArray(dateRange) ? dateRange[1] : dateRange.to)) as Date | undefined;

  const availabilityConditions =
    dateFrom && dateTo
      ? {
          availableFrom: transformDateToString(dateFrom, 'DATE'),
          availableTo: transformDateToString(dateTo, 'DATE'),
        }
      : {};

  const officersResource = getResourcesUrl('users', {
    ...officerConditions,
    ...availabilityConditions,
  });

  const officersResourceStale = useRef(true);
  useEffect(() => {
    officersResourceStale.current = true;
  }, [officersResource]);

  const { reload: loadOfficers, data: officersData } = useResources<User>('officers', {
    autoload: false,
    stale: true,
    url: officersResource,
  });

  const dcoReducer = useOfficersReducer(availabilityCategories, dcoReducerOptions);
  const bcoReducer = useOfficersReducer(availabilityCategories, bcoReducerOptions);
  const chaperoneReducer = useOfficersReducer(availabilityCategories, chaperoneReducerOptions);

  // TODO: improve support in useIncludeOptions teamMemberssId => teamMembers
  const teamMembersInclude = useIncludeOptions('teamMemberssId') as TeamMember[];
  const dcosInclude = useMemo(
    () =>
      (teamMembersInclude || [])
        .filter(
          (it) =>
            it.status === TeamMemberStatus.CONFIRMED &&
            (it.roles || []).includes(AccreditationsRole.DCO)
        )
        .map((it) => it.user),
    [teamMembersInclude]
  );
  const bcosInclude = useMemo(
    () =>
      (teamMembersInclude || [])
        .filter(
          (it) =>
            it.status === TeamMemberStatus.CONFIRMED &&
            (it.roles || []).includes(AccreditationsRole.BCO)
        )
        .map((it) => it.user),
    [teamMembersInclude]
  );
  const chaperoneInclude = useMemo(
    () =>
      (teamMembersInclude || [])
        .filter(
          (it) =>
            it.status === TeamMemberStatus.CONFIRMED &&
            (it.roles || []).includes(AccreditationsRole.CHAPERONE)
        )
        .map((it) => it.user),
    [teamMembersInclude]
  );
  const leadDcoInclude = useMemo(
    () =>
      (teamMembersInclude || [])
        .filter(
          (it) =>
            it.status === TeamMemberStatus.CONFIRMED &&
            (it?.user?.accreditations || [])
              .map((it) => it.role)
              .includes(AccreditationsRole.LEAD_DCO)
        )
        .map((it) => it.user),
    [teamMembersInclude]
  );

  const leadDcoReducer = useCallback(
    (list: User[]) =>
      list
        .filter((it) => {
          const allowedRoles = (it.accreditations || [])
            .filter((it) => it.role)
            .map((it) => it.role);
          return (
            ((dcos && dcos.includes(it.id)) || (bcos && bcos.includes(it.id))) &&
            allowedRoles.includes(AccreditationsRole.LEAD_DCO)
          );
        })
        .map((item) => ({
          id: item.id,
          name: item.fullName || item.email,
        })),
    [bcos, dcos]
  );

  const dcoOptions = useMemo(
    () => dcoReducer(officersData?.length ? officersData : dcosInclude || [], dcos),
    [dcoReducer, dcos, dcosInclude, officersData]
  );

  const chaperoneOptions = useMemo(
    () =>
      chaperoneReducer(officersData?.length ? officersData : chaperoneInclude || [], chaperones),
    [chaperoneReducer, officersData, chaperones, chaperoneInclude]
  );

  const bcoOptions = useMemo(
    () => bcoReducer(officersData?.length ? officersData : bcosInclude || [], bcos),
    [bcoReducer, bcos, bcosInclude, officersData]
  );

  const leadDcoOptions = useMemo(
    () => leadDcoReducer(officersData?.length ? officersData : leadDcoInclude || []),
    [leadDcoReducer, leadDcoInclude, officersData]
  );

  const state = useContext(FormContext);
  const isViewModeActive = Boolean(
    state.viewMode && state.defaultViewMode && !limitedAssignmentAllowed
  );
  const leadDcoViewMode = !isViewModeActive
    ? !((bcos && bcos.length > 0) || (dcos && dcos.length > 0))
    : isViewModeActive;
  const redirectToAgenda = useRedirectToAgenda();

  const changeOfficers = useCallback(
    (
      dcoUsers: { [id: number]: Option & { extra: User } } | null,
      fieldName: 'dcos' | 'bcos' | 'chaperones',
      changeRole: AccreditationsRole.DCO | AccreditationsRole.BCO | AccreditationsRole.CHAPERONE
    ) => {
      const newIds: number[] = Object.keys(dcoUsers || {}).map((it) => Number(it));

      setValues((oldValues) => {
        // Remove DCO/BCO role from all assigned members
        const newAssigned = (oldValues.assignedMembers || []).map((it) => ({
          ...it,
          roles: (it.roles || []).filter((it) => it !== changeRole),
        }));

        // Add new assigned members / add DCO Role to existing assigned members
        newIds.forEach((usersId) => {
          const existingInvited = (oldValues.invitedMembers || []).find(
            (it) => it.usersId === usersId
          );
          const existingAssigned = newAssigned.find((it) => it.usersId === usersId);
          const newUser: User = dcoUsers![usersId]?.extra;

          if (!existingAssigned && !existingInvited) {
            // Add new team member to assigned members
            newAssigned.push({
              usersId: newUser.id,
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              fullName: newUser.fullName!,
              avatarStorageKey: newUser.avatarStorageKey!,
              status: TeamMemberStatus.CONFIRMED,
              internalComment: null,
              statement: null,
              invitations: [],
              assignmentNotifications: [],
              availabilities: newUser.availabilities,
              labels: newUser.labels || [],
              alreadySavedRoles: null,
              roles: [changeRole],
              allowedRoles: (newUser.accreditations || [])
                .filter((it) => !!it.role)
                .map((it) => it.role),
            });
          } else if (!existingAssigned && existingInvited) {
            newAssigned.push({
              ...existingInvited,
              status: TeamMemberStatus.CONFIRMED,
              roles: [changeRole],
            });
          } else {
            existingAssigned!.roles = [...existingAssigned!.roles, changeRole];
          }
        });

        const removeLeadDco: TeamMemberData | undefined = newAssigned.find(
          (it) => (it.roles || []).includes(AccreditationsRole.LEAD_DCO) && it.roles.length === 1
        );

        return {
          ...oldValues,
          // Filter assigned that has empty roles or it's lead DCO without being DCO, should be removed too
          assignedMembers: newAssigned.filter(
            (it) => it.roles?.length && it.usersId !== removeLeadDco?.usersId
          ),
          invitedMembers: (oldValues.invitedMembers || []).filter(
            (invitedMember) => !newIds.includes(invitedMember.usersId)
          ),
          [fieldName]: newIds.length > 0 ? newIds : null,
          leadDcosId: removeLeadDco ? null : oldValues.leadDcosId,
        };
      });
    },
    [setValues]
  );

  const changeLeadDco = useCallback(
    (newLeadDcosId: number | null) => {
      setValues((oldValues) => {
        // Remove Lead DCO role from all assigned members
        const newAssigned = (oldValues.assignedMembers || []).map((it) => ({
          ...it,
          roles: (it.roles || []).filter((it) => it !== AccreditationsRole.LEAD_DCO),
        }));

        return {
          ...oldValues,
          // Add role LEAD DCO to the existing team member
          assignedMembers: newAssigned.map((it) => ({
            ...it,
            roles:
              it.usersId === newLeadDcosId
                ? Array.from(
                    new Set([...it.roles, AccreditationsRole.LEAD_DCO, AccreditationsRole.DCO])
                  )
                : it.roles,
          })),
          dcos:
            newLeadDcosId && !(oldValues.dcos || []).includes(newLeadDcosId)
              ? [...(oldValues.dcos || []), newLeadDcosId]
              : oldValues.dcos,
          leadDcosId: newLeadDcosId,
        };
      });
    },
    [setValues]
  );

  return (
    <>
      <FullRowCell>
        <FlexRow verticalAlign="normal">
          <FlexCell block flex={1}>
            <Field
              component={FormDropDown}
              label={t('Assign DCO(s)')}
              fast={false}
              name="dcos"
              viewMode={!!state?.viewMode?.dcos || !canPatchTeam}
              options={dcoOptions}
              onChange={(
                ids: number[] | null,
                dcoUsers: { [id: number]: Option & { extra: User } } | null
              ) => {
                if (
                  limitedAssignmentAllowed &&
                  alreadySavedDcosId &&
                  alreadySavedDcosId.some((it) => (ids || []).indexOf(it) < 0)
                ) {
                  toast.error(t('Already saved DCOs can not be removed') as string);
                  return;
                }
                changeOfficers(dcoUsers, 'dcos', AccreditationsRole.DCO);
              }}
              onOpen={() => {
                if (officersResourceStale.current) {
                  loadOfficers();
                  officersResourceStale.current = false;
                }

                if (availabilitiesEnabled) {
                  loadAvailabilityCategories(false);
                }
              }}
            />
          </FlexCell>
          {availabilitiesEnabled && canPatchTeam && (
            <TopSpacedFlexCell>
              <IconButton
                icon="calendar_today"
                tooltip={t('Open Agenda with accredited DCOs in the given date range')}
                onClick={() =>
                  redirectToAgenda({ accreditation: dcoAccreditations, dateFrom, dateTo })
                }
              />
            </TopSpacedFlexCell>
          )}
        </FlexRow>
      </FullRowCell>

      {(hasBlood || isBulkEdit) && (
        <FullRowCell>
          <FlexRow>
            <FlexCell block flex={1}>
              <Field
                component={FormDropDown}
                label={t('Assign BCO(s)')}
                fast={false}
                name="bcos"
                viewMode={!!state?.viewMode?.bcos || !canPatchTeam}
                options={bcoOptions}
                onChange={(
                  ids: number[] | null,
                  bcoUsers: { [id: number]: Option & { extra: User } } | null
                ) => {
                  if (
                    limitedAssignmentAllowed &&
                    alreadySavedBcosId &&
                    alreadySavedBcosId.some((it) => (ids || []).indexOf(it) < 0)
                  ) {
                    toast.error(t('Already saved BCOs can not be removed') as string);
                    return;
                  }

                  changeOfficers(bcoUsers, 'bcos', AccreditationsRole.BCO);
                }}
                onOpen={() => {
                  if (officersResourceStale.current) {
                    loadOfficers();
                    officersResourceStale.current = false;
                  }

                  if (availabilitiesEnabled) {
                    loadAvailabilityCategories(false);
                  }
                }}
              />
            </FlexCell>
            {availabilitiesEnabled && canPatchTeam && (
              <TopSpacedFlexCell>
                <IconButton
                  icon="calendar_today"
                  tooltip={t('Open Agenda with accredited BCOs in the given date range')}
                  onClick={() =>
                    redirectToAgenda({ accreditation: bcoAccreditations, dateFrom, dateTo })
                  }
                />
              </TopSpacedFlexCell>
            )}
          </FlexRow>
        </FullRowCell>
      )}

      <FullRowCell>
        <FlexRow>
          <FlexCell block flex={1}>
            <Field
              component={FormDropDown}
              label={t('Assign Chaperone(s)')}
              fast={false}
              name="chaperones"
              viewMode={!!state?.viewMode?.chaperones || !canPatchTeam}
              options={chaperoneOptions}
              onChange={(
                ids: number[] | null,
                chaperoneUsers: { [id: number]: Option & { extra: User } } | null
              ) => {
                if (
                  limitedAssignmentAllowed &&
                  alreadySavedChaperonesId &&
                  alreadySavedChaperonesId.some((it) => (ids || []).indexOf(it) < 0)
                ) {
                  toast.error(t('Already saved DCOs can not be removed') as string);
                  return;
                }
                changeOfficers(chaperoneUsers, 'chaperones', AccreditationsRole.CHAPERONE);
              }}
              onOpen={() => {
                if (officersResourceStale.current) {
                  loadOfficers();
                  officersResourceStale.current = false;
                }

                if (availabilitiesEnabled) {
                  loadAvailabilityCategories(false);
                }
              }}
            />
          </FlexCell>
          {availabilitiesEnabled && canPatchTeam && (
            <TopSpacedFlexCell>
              <IconButton
                icon="calendar_today"
                tooltip={t('Open Agenda with accredited Chaperones in the given date range')}
                onClick={() =>
                  redirectToAgenda({ accreditation: CHAPERONE_ACCREDITATION, dateFrom, dateTo })
                }
              />
            </TopSpacedFlexCell>
          )}
        </FlexRow>
      </FullRowCell>

      <FullRowCell>
        <Field
          resource={officersResource}
          component={FormDropDown}
          options={leadDcoOptions}
          label={t('Lead DCO')}
          fast={false}
          single
          name="leadDcosId"
          viewMode={leadDcoViewMode || !canPatchTeam}
          onChange={changeLeadDco}
          onOpen={() => {
            if (officersResourceStale.current) {
              loadOfficers();
              officersResourceStale.current = false;
            }

            if (availabilitiesEnabled) {
              loadAvailabilityCategories(false);
            }
          }}
        />
      </FullRowCell>
    </>
  );
};

export default OfficersFieldset;
