import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import { useAthleteColumn } from 'common/columns/useAthleteColumn';
import { sampleTypesToGrid, useSampleTypeColumn } from 'common/columns/useSampleTypeColumn';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import Analysis from 'lists/models/Analysis';
import Coc from 'planning/models/Coc';
import Sample from 'planning/models/Sample';

const useTableDefinitions = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const getAthleteColumn = useAthleteColumn();

  const sampleTypeColumn = useSampleTypeColumn();
  const sampleTypeIcons = useMemo(
    () => [
      {
        id: 'urine',
        content: (data: { urineAnalyses: Analysis[] }) =>
          data.urineAnalyses?.length > 0
            ? data.urineAnalyses.map((item: Analysis) => item.shortName).join(', ')
            : t('No Urine analyses'),
        color: (data: { urineAnalyses: Analysis[] }) =>
          data.urineAnalyses?.length > 0 ? theme.color.status.orange : theme.color.orange600,
        size: 1.25,
        name: 'urine',
      },
      {
        id: 'blood',
        content: (data: { bloodAnalyses: Analysis[] }) =>
          data.bloodAnalyses?.length > 0
            ? data.bloodAnalyses.map((item: Analysis) => item.shortName).join(', ')
            : t('No Blood analyses'),
        color: (data: { bloodAnalyses: Analysis[] }) =>
          data.bloodAnalyses?.length > 0 ? theme.color.status.red : theme.color.red600,
        size: 1.25,
        name: 'blood',
      },
      {
        id: 'dbs',
        content: (data: { dbsAnalyses: Analysis[] }) =>
          data.dbsAnalyses?.length > 0
            ? data.dbsAnalyses.map((item: Analysis) => item.shortName).join(', ')
            : t('Not DBS analyses'),
        color: (data: { dbsAnalyses: Analysis[] }) =>
          data.dbsAnalyses?.length > 0 ? theme.color.burgundy : theme.color.burgundy600,
        size: 1.25,
        name: 'dbs',
      },
    ],
    [t]
  );

  return useMemo(
    (): Record<string, () => { title: string; columns: ColDef[] }> =>
      ({
        tests: () => ({
          title: t('Tests'),
          columns: [
            {
              headerName: t('Test ID'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 80,
            },
            getAthleteColumn(),
            {
              ...sampleTypeColumn,
              field: 'sampleType',
              cellRendererParams: { icons: sampleTypeIcons },
            },
            {
              headerName: t('Done Date'),
              valueGetter: ({ data }: any) =>
                data.resultPerformedAt
                  ? formatDateTime(data.resultPerformedAt, 'DATE_SHORT')
                  : data.resultCreatedAt
                    ? formatDateTime(data.resultCreatedAt, 'DATE_SHORT')
                    : t('Open'),
            },
          ],
        }),
        reports: () => ({
          title: t('Control Reports'),
          columns: [
            {
              headerName: t('Test ID'),
              field: 'testsId',
              sortable: true,
              sort: 'desc',
              width: 80,
            },
            {
              headerName: t('DCO'),
              field: 'dcoName',
            },
            getAthleteColumn(),
            {
              headerName: t('Created'),
              valueGetter: (params: any) => formatDateTime(params.data.createdAt, 'DATE_SHORT'),
              field: 'createdAt',
            },
          ],
        }),

        uaReports: () => ({
          title: t('UA Reports'),
          columns: [
            {
              headerName: t('Test ID'),
              field: 'testsId',
              sortable: true,
              sort: 'desc',
              width: 80,
            },
            {
              headerName: t('DCO'),
              field: 'dcoName',
            },
            getAthleteColumn(),
            {
              headerName: t('Created'),
              valueGetter: (params: any) => formatDateTime(params.data.createdAt, 'DATE_SHORT'),
              field: 'createdAt',
            },
          ],
        }),

        cocs: () => ({
          title: t('CoCs'),
          columns: [
            {
              headerName: t('No.'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 80,
            },
            {
              valueGetter: (params: any) => params.data.lab && params.data.lab.shortName,
              headerName: t('Lab'),
              field: 'lab',
            },
            {
              valueGetter: (params: any) =>
                params.data.personalDelivery ? t('Personal') : t('Courier'),
              headerName: t('Delivery'),
              field: 'deliveryCustom',
            },
            {
              valueGetter: (params: { data: Coc }) =>
                params.data.shippedAt
                  ? formatDateTime(params.data.shippedAt, 'DATE_SHORT')
                  : t('N/A'),
              headerName: t('Date'),
              field: 'shippedAt',
              width: 200,
            },
            {
              headerName: t('Waybill'),
              field: 'waybill',
            },
          ],
        }),

        samples: () => ({
          title: t('Samples'),
          columns: [
            {
              headerName: t('Sample ID'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 120,
            },
            {
              headerName: t('Code'),
              field: 'code',
            },
            {
              ...sampleTypeColumn,
              headerName: t('Type'),
              field: 'sampleType',
              cellRendererParams: {
                icons: sampleTypesToGrid(),
              },
            },
            {
              headerName: t('Stamp'),
              field: 'performedAt',
              valueGetter: (params: { data: Sample }) =>
                params.data.performedAt
                  ? formatDateTime(params.data.performedAt, 'DATE_SHORT')
                  : t('N/A'),
              width: 200,
            },
          ],
        }),
      }) as const,
    [t, formatDateTime, getAthleteColumn, sampleTypeIcons, sampleTypeColumn]
  );
};

export default useTableDefinitions;
