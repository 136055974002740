import { useCallback } from 'react';

import { LabelsFormData } from './useLabelsSchema';

const useLabelsOutputMapping = () =>
  useCallback(
    (fd: LabelsFormData) => ({
      labels: (fd.labels || []).filter((it) => it).map((labelsId) => ({ labelsId })),
    }),
    []
  );

export default useLabelsOutputMapping;
