// Most of these would be automagically guessed,
// but need to be explicitly listed for loading from mission
export const includeResourceMapping = {
  missionsId: 'mission',
  assigneesId: 'assignee',
  leadDcosId: 'leadDco',
  athletesId: 'athlete',
  federationsId: 'federation',
  dcos: 'dcos',
  bcos: 'bcos',
  testSituationsId: 'testSituation',
  poolsId: 'pool',
  adamsAthleteLevelsId: 'adamsAthleteLevel',
  sportsId: 'sport',
  sportDisciplinesId: 'sportDiscipline',
  countriesId: 'country',
  testAuthority: 'testsAuthority',
  resultAuthority: 'resultsAuthority',
  sampleAuthoritiesId: 'sampleAuthority',
  initiatorAuthoritiesId: 'initiatorAuthority',
  bloodLabsId: 'bloodLab',
  urineLabsId: 'urineLab',
  dbsLabsId: 'dbsLab',
  fileTemplatesId: 'fileTemplate',
  locationsId: 'location',
  bloodAnalyses: 'bloodAnalyses',
  urineAnalyses: 'urineAnalyses',
  dbsAnalyses: 'dbsAnalyses',
  teamMembers: 'teamMembers',
  timeZone: 'timeZone',
} as const;
