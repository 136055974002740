import { setIn } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormFieldset from 'core/components/Form/FormFieldset';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormSwitch from 'core/components/FormSwitch';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import IconButton from 'core/components/IconButton';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useResources } from 'core/hooks/useResource';
import { useLaboratoryDropdownReducer } from 'lists/containers/LaboratoriesPage/useLaboratoryDropdownReducer';
import Analysis from 'lists/models/Analysis';
import useIncludeOverride from 'planning/containers/TestDetailPage/hooks/useIncludeOverride';
import useAnalysesOptionsReducer from 'planning/hooks/useAnalysesOptionsReducer';
import { BLOOD_ANALYSES_URL } from 'planning/resources/analyses';

import { AnalysesFieldsData } from '../useAnalysesFieldsSchema';

import BloodSamplesField from './BloodSamplesField';
import OtherBloodAnalyses from './OtherBloodAnalyses';

interface Props {
  onRequestEditAnalyses?: () => void;
  hideNonAnalysesFields?: boolean;
}

const BloodFields: FC<Props> = ({ hideNonAnalysesFields, onRequestEditAnalyses }) => {
  const { t } = useTranslation();
  const labsReducer = useLaboratoryDropdownReducer();

  const chosenBloodAnalyses: (number | 'other')[] = useFormFieldValue('bloodAnalyses');

  const { entityData } = useDetail<{ bloodAnalyses: Analysis[] }>();
  const [includes] = useIncludeOverride();
  const { data: bloodAnalyses, reload: loadAnalyses } = useResources<Analysis>(BLOOD_ANALYSES_URL, {
    autoload: (chosenBloodAnalyses || []).includes('other'),
  });
  const { analyses, otherAnalyses } = useMemo(() => {
    const analyses: Analysis[] = [];
    const otherAnalyses: Analysis[] = [];

    (bloodAnalyses?.length
      ? bloodAnalyses
      : entityData?.bloodAnalyses.length
        ? entityData.bloodAnalyses
        : (includes?.bloodAnalyses as Analysis[]) || []
    ).forEach((it) => {
      if (it.code.startsWith('ADT')) {
        otherAnalyses.push(it);
      } else {
        analyses.push(it);
      }
    });

    return { analyses, otherAnalyses };
  }, [bloodAnalyses, entityData, includes]);

  const analysesOptionsReducer = useAnalysesOptionsReducer();

  const dropdownOptions = useMemo(() => {
    return [
      ...analysesOptionsReducer(
        analyses.filter((it) => it.isSelectable),
        chosenBloodAnalyses
      ),
      'SEPARATOR' as Option,
      { id: 'other', name: t('Other') },
    ];
  }, [analyses, analysesOptionsReducer, chosenBloodAnalyses, t]);

  const decorateValues = useCallback(
    (name: keyof AnalysesFieldsData | undefined, value: any, values: AnalysesFieldsData) => {
      if (name === 'bloodAnalyses') {
        const next = new Set(value || []);
        const prev = new Set((values.bloodAnalyses as any[]) || []);
        if (prev.has('other') && !next.has('other')) {
          values.otherBloodAnalyses = [];
        } else if (!prev.has('other') && next.has('other') && otherAnalyses.length > 0) {
          // @ts-ignore TODO: check
          values.otherBloodAnalyses = [{ id: otherAnalyses[0].id, remarks: null }];
        }
      }

      if (name) {
        return setIn(values, name, value);
      }
      return values;
    },
    [otherAnalyses]
  );

  return (
    <FormFieldset<AnalysesFieldsData> onChange={decorateValues}>
      <Row>
        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            endAdornment={
              onRequestEditAnalyses && (
                <IconButton
                  onClick={onRequestEditAnalyses}
                  type="button"
                  icon="edit"
                  tooltip={t('Edit')}
                />
              )
            }
            label={t('Blood Analyses')}
            options={dropdownOptions}
            component={FormDropDown}
            onOpen={() => {
              if (!bloodAnalyses?.length) {
                loadAnalyses();
              }
            }}
            name="bloodAnalyses"
            sortOptions={false}
            id="bloodAnalyses"
            fast={false}
          />
        </Cell>

        <OtherBloodAnalyses
          analysesFieldName="bloodAnalyses"
          name="otherBloodAnalyses"
          analyses={otherAnalyses}
        />

        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            dummyPlaceholder={BloodSamplesField}
            component={BloodSamplesField}
            name="bloodAnalyses"
            analyses={[...analyses, ...otherAnalyses]}
            diffMode={false}
            fast={false}
          />
        </Cell>
        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            component={ResourceFormDropdown}
            label={t('Laboratory')}
            name="bloodLabsId"
            id="bloodLabsId"
            resource="labs"
            single
            fast
            resourceReducer={labsReducer}
          />
        </Cell>

        {!hideNonAnalysesFields && (
          <>
            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={FormSwitch}
                label={t('Expedited Analysis')}
                name="bloodExpedited"
                id="bloodExpedited"
                fast
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={FormSwitch}
                label={t('Long-Term Storage')}
                name="bloodLts"
                id="bloodLts"
                fast
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                label={t('Analysis Remarks')}
                component={FormTextfield}
                name="bloodComments"
                textarea
              />
            </Cell>
          </>
        )}
      </Row>
    </FormFieldset>
  );
};

export default BloodFields;
