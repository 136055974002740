import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import Accreditation, { AccreditationsId } from 'app/models/Accreditation';
import { stringify } from 'core/functions/qs';
import useDropdownResource from 'core/hooks/useDropdownResource';
import { AnalysesFieldsData } from 'planning/components/AnalysesFields';

const useAccreditationRolesOptions = (shouldLoad = false) => {
  const { values } = useFormikContext<AnalysesFieldsData>();
  const hasBloodAnalyses =
    values.bloodAnalyses?.length > 0 || values.otherBloodAnalyses?.length > 0;
  const hasDbsAnalyses = values.dbsAnalyses?.length > 0 || values.otherDbsAnalyses?.length > 0;
  const [options] = useDropdownResource(
    `accreditations?${stringify({ role: { $not: '\\x00' } })}`,
    useMemo(
      () => ({
        reducer: (accreditations: Accreditation[]) => {
          const list =
            hasBloodAnalyses || hasDbsAnalyses
              ? accreditations
              : accreditations.filter((a) => a.id !== AccreditationsId.BCO);
          return list.map((a) => ({ id: a.role, name: a.name }));
        },
        autoload: shouldLoad,
      }),
      [hasBloodAnalyses, shouldLoad, hasDbsAnalyses]
    )
  );

  return options;
};
export default useAccreditationRolesOptions;
