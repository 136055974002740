import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';

const tabMeta = getTabMeta();

/**
 * meta tab section
 * @param section
 * Enable empty analyses
 * @param enableEmptyAnalyses
 */
const useAnalysesFieldsSchema = (section: string, enableEmptyAnalyses?: boolean) => {
  const metaTab = useMemo(() => (section ? tabMeta(section) : {}), [section]);
  const { t } = useTranslation();

  return useMemo(
    () =>
      Yup.object({
        // Blood
        bloodLabsId: Yup.number().nullable().meta(metaTab).default(null),
        bloodComments: Yup.string().nullable().meta(metaTab).default(null),
        bloodAnalyses: Yup.array(Yup.mixed())
          .test('analysesNone', t('All analyses cannot be none'), function (value) {
            return (
              enableEmptyAnalyses ||
              (this.parent.urineAnalyses && this.parent.urineAnalyses.length > 0) ||
              (this.parent.dbsAnalyses && this.parent.dbsAnalyses.length > 0) ||
              (value && value.length > 0)
            );
          })
          .meta(metaTab)
          .ensure()
          .default([]),
        otherBloodAnalyses: Yup.array(
          Yup.object().shape({
            id: Yup.number().required().meta(metaTab),
            remarks: Yup.string().nullable().required().meta(metaTab).default(null),
          })
        )
          .ensure()
          .meta(metaTab)
          .default([]),
        bloodExpedited: Yup.boolean().meta(metaTab).default(false),
        bloodLts: Yup.boolean().meta(metaTab).default(false),

        // Urine
        urineLabsId: Yup.number().nullable().meta(metaTab).default(null),
        urineComments: Yup.string().nullable().meta(metaTab).default(null),
        urineAnalyses: Yup.array(Yup.mixed().meta(metaTab))
          .test('analysesNone', t('All analyses cannot be none'), function (value) {
            return (
              enableEmptyAnalyses ||
              (this.parent.bloodAnalyses && this.parent.bloodAnalyses.length > 0) ||
              (this.parent.dbsAnalyses && this.parent.dbsAnalyses.length > 0) ||
              (value && value.length > 0)
            );
          })
          .meta(metaTab)
          .ensure()
          .default([]),

        otherUrineAnalyses: Yup.array(
          Yup.object().shape({
            id: Yup.number().required().meta(metaTab),
            remarks: Yup.string().nullable().required().meta(metaTab).default(null),
          })
        )
          .ensure()
          .meta(metaTab)
          .default([]),
        urineExpedited: Yup.boolean().meta(metaTab).default(false),
        urineLts: Yup.boolean().meta(metaTab).default(false),

        // DBS
        dbsLabsId: Yup.number().nullable().meta(metaTab).default(null),
        dbsComments: Yup.string().nullable().meta(metaTab).default(null),
        dbsAnalyses: Yup.array(Yup.mixed().meta(metaTab))
          .test('analysesNone', t('All analyses cannot be none'), function (value) {
            return (
              enableEmptyAnalyses ||
              (this.parent.urineAnalyses && this.parent.urineAnalyses.length > 0) ||
              (this.parent.bloodAnalyses && this.parent.bloodAnalyses.length > 0) ||
              (value && value.length > 0)
            );
          })
          .meta(metaTab)
          .ensure()
          .default([]),

        otherDbsAnalyses: Yup.array(
          Yup.object().shape({
            id: Yup.number().required().meta(metaTab),
            remarks: Yup.string().nullable().required().meta(metaTab).default(null),
          })
        )
          .ensure()
          .meta(metaTab)
          .default([]),
        dbsExpedited: Yup.boolean().meta(metaTab).default(false),
        dbsLts: Yup.boolean().meta(metaTab).default(false),
      }),
    [metaTab, t, enableEmptyAnalyses]
  );
};

export type AnalysesFieldsData = Yup.TypeOf<ReturnType<typeof useAnalysesFieldsSchema>>;

export default useAnalysesFieldsSchema;
