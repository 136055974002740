import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AccreditationsRole } from 'app/models/Accreditation';
import { getTabMeta } from 'core/components/Form/TabErrorCollector';

import { NotificationType } from './enums';
import { TeamMemberData } from './useTeamInputMapping';

const tabMeta = getTabMeta();

export const useSingleMemberSchema = (section: string) => {
  const { t } = useTranslation();
  const metaTab = useMemo(() => (section ? tabMeta(section) : {}), [section]);

  const notificationSchema = Yup.array(
    Yup.object({
      type: Yup.string().oneOf([NotificationType.SMS, NotificationType.EMAIL]).meta(metaTab),
      sentType: Yup.string().oneOf([NotificationType.SMS, NotificationType.EMAIL]).meta(metaTab),
      sentAt: Yup.date().meta(metaTab),
      createdAt: Yup.date().meta(metaTab),
    }).meta(metaTab)
  )
    .ensure()
    .meta(metaTab)
    .default([]);

  return useMemo(
    () =>
      Yup.object({
        usersId: Yup.number().required().meta(metaTab),
        status: Yup.number().meta(metaTab),
        internalComment: Yup.string().nullable().meta(metaTab).default(null),
        statement: Yup.string().nullable().meta(metaTab).default(null),
        roles: Yup.array(Yup.string().meta(metaTab))
          .required()
          .meta(metaTab)
          .test('accredited', t('Not accredited for the selected roles'), function (value: any) {
            const allowedRoles: number[] = this.parent.allowedRoles || [];
            const hasAll =
              value &&
              Array.isArray(value) &&
              value.every((pickedRole) => allowedRoles.includes(pickedRole));
            return hasAll;
          })
          .min(1)
          .ensure()
          .default([]),
        invitations: notificationSchema,
        assignmentNotifications: notificationSchema,
        // Read-only data that are passed around
        firstName: Yup.string().nullable().meta(metaTab),
        lastName: Yup.string().nullable().meta(metaTab),
        fullName: Yup.string().nullable().meta(metaTab),
        avatarStorageKey: Yup.string().nullable().meta(metaTab),
        availabilities: Yup.array().meta(metaTab),
        labels: Yup.array().meta(metaTab),
        allowedRoles: Yup.array(Yup.string()).meta(metaTab).nullable().default([]),
        alreadySavedRoles: Yup.array(Yup.string()).meta(metaTab).nullable().default(null),
      })
        .test('leadDco', t('There can be only one Lead DCO'), function (value) {
          const members = this.parent;
          if (!members) return true;

          const leadCount = members.reduce((acc: number, member: TeamMemberData) => {
            if (member.roles.includes(AccreditationsRole.LEAD_DCO)) acc++;
            return acc;
          }, 0);

          return value.roles.includes(AccreditationsRole.LEAD_DCO) && leadCount > 1
            ? this.createError({ path: `${this.path}.roles` })
            : true;
        })
        .meta(metaTab),
    [t, metaTab, notificationSchema]
  );
};

const useTeamMembersSchema = (section: string) => {
  const singleMemberSchema = useSingleMemberSchema(section);
  const metaTab = useMemo(() => (section ? tabMeta(section) : {}), [section]);

  const membersSchema = useMemo(
    () => Yup.array(singleMemberSchema).meta(metaTab).ensure().default([]),
    [singleMemberSchema, metaTab]
  );

  return useMemo(
    () => ({
      invitedMembers: membersSchema,
      assignedMembers: membersSchema,
      notifyOfTeamUpdates: Yup.boolean().meta(metaTab).default(false),
      showAthleteToDco: Yup.boolean().meta(metaTab).default(false),
    }),
    [metaTab, membersSchema]
  );
};

export default useTeamMembersSchema;
