import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';

const tabMeta = getTabMeta();

export const useLabelsSchema = (meta: string = 'detail') =>
  Yup.object({
    labels: Yup.array(Yup.number().default(0)).nullable().meta(tabMeta(meta)).default(null),
  });

export type LabelsFormData = Yup.TypeOf<ReturnType<typeof useLabelsSchema>>;

export default useLabelsSchema;
