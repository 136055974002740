import { FieldProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DummyField from 'core/components/DummyField';

const DbsSamples: FC<FieldProps> = ({ field: { value } }) => {
  const { t } = useTranslation();
  return <DummyField value={value} label={t('DBS Sample(s)')} boolean />;
};

export default DbsSamples;
