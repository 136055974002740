import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDownFilter } from 'core/containers/DataGrid';

const DEFAULT_VALUE = ['any'];

const useSampleTypeFilter = (): DropDownFilter => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      type: 'dropdown',
      label: t('Sample Type'),
      defaultValue: DEFAULT_VALUE,
      single: false,
      applyToParams: (value) => {
        const val = Array.isArray(value) ? value : [value];
        if (!Array.isArray(value) || value.length === 0 || value.indexOf('any') > -1) {
          return {};
        }

        const conditions = [];
        if (val.includes('urine')) {
          conditions.push({ urineAnalysesCount: { $gte: 1 } });
        }
        if (val.includes('blood')) {
          conditions.push({ bloodAnalysesCount: { $gte: 1 } });
        }
        if (val.includes('dbs')) {
          conditions.push({ dbsAnalysesCount: { $gte: 1 } });
        }
        return {
          having: conditions.length > 1 ? { $or: { ...conditions } } : conditions,
        };
      },

      onChange: (newVal, oldVal, onFiltersChange) => {
        let value: string[] = Array.isArray(newVal) ? (newVal as string[]) : [];
        const prev = new Set(Array.isArray(oldVal) ? (oldVal as string[]) : []);
        const next = new Set(Array.isArray(newVal) ? (newVal as string[]) : []);

        if (next.has('any') && !prev.has('any')) {
          value = ['any'];
        } else {
          if (prev.has('any') && next.has('any')) {
            value = value.filter((it) => it !== 'any');
          }
        }
        onFiltersChange(!value || value.length === 0 ? DEFAULT_VALUE : value);
      },

      options: [
        { id: 'any', name: t('Any') },
        { id: 'blood', name: t('Blood') },
        { id: 'urine', name: t('Urine') },
        { id: 'dbs', name: t('DBS') },
      ],
    }),
    [t]
  );
};

export const useGetSampleTypeFilterValue = (): ((
  bloodAnalysesCount: number | null,
  urineAnalysesCount: number | null,
  dbsAnalysesCount: number | null
) => string[] | string | undefined) => {
  return useCallback((bloodAnalysesCount, urineAnalysesCount, dbsAnalysesCount) => {
    if (!!bloodAnalysesCount) return 'blood';
    else if (!!urineAnalysesCount) return 'urine';
    else if (!!dbsAnalysesCount) return 'dbs';
    return undefined;
  }, []);
};

export const useGetSampleTypeFilterName = (): ((
  bloodAnalysesCount: number | null,
  urineAnalysesCount: number | null,
  dbsAnalysesCount: number | null
) => string | undefined) => {
  const { t } = useTranslation();
  return useCallback(
    (bloodAnalysesCount, urineAnalysesCount, dbsAnalysesCount) => {
      if (!!bloodAnalysesCount) return t('Blood');
      else if (!!urineAnalysesCount) return t('Urine');
      else if (!!dbsAnalysesCount) return t('DBS');
      return undefined;
    },
    [t]
  );
};

export default useSampleTypeFilter;
