import { setIn } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormFieldset from 'core/components/Form/FormFieldset';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormSwitch from 'core/components/FormSwitch';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import IconButton from 'core/components/IconButton';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useResources } from 'core/hooks/useResource';
import { useLaboratoryDropdownReducer } from 'lists/containers/LaboratoriesPage/useLaboratoryDropdownReducer';
import Analysis from 'lists/models/Analysis';
import useIncludeOverride from 'planning/containers/TestDetailPage/hooks/useIncludeOverride';
import { DBS_ANALYSES_URL } from 'planning/resources/analyses';

import { AnalysesFieldsData } from '../useAnalysesFieldsSchema';

import DbsSamplesField from './DbsSamples';
import OtherDbsRow from './OtherDbsRow';

interface Props {
  hideNonAnalysesFields?: boolean;
  onRequestEditAnalyses?: () => void;
}

const DbsFields: FC<Props> = ({ hideNonAnalysesFields, onRequestEditAnalyses }) => {
  const { t } = useTranslation();
  const labsReducer = useLaboratoryDropdownReducer();

  const { entityData } = useDetail<{ dbsAnalyses: Analysis[] }>();
  const [includes] = useIncludeOverride();
  const { data: dbsAnalyses, reload: loadAnalyses } = useResources<Analysis>(DBS_ANALYSES_URL, {
    autoload: false,
  });

  const chosenDbsAnalyses: number[] = useFormFieldValue('dbsAnalyses');

  const { otherDbsAnalysesOptions, dbsAnalysesOptions } = useMemo(() => {
    const analyses: Analysis[] = [];
    const otherDbsAnalyses: Analysis[] = [];

    (dbsAnalyses?.length
      ? dbsAnalyses
      : entityData?.dbsAnalyses
        ? (entityData?.dbsAnalyses as Analysis[])
        : (includes?.dbsAnalyses as Analysis[]) || []
    ).forEach((it) => {
      if (it.code.startsWith('ADT')) {
        otherDbsAnalyses.push(it);
      } else {
        analyses.push(it);
      }
    });

    return {
      otherDbsAnalysesOptions: otherDbsAnalyses.map(({ id, shortName: name }) => ({
        id,
        name,
      })) as Option[],
      dbsAnalysesOptions: analyses
        .filter((it) => (it.isSelectable && it.active) || chosenDbsAnalyses.includes(it.id))
        .map(({ id, shortName: name, fullName: secondary }) => ({ id, name, secondary }) as Option)
        .concat(['SEPARATOR' as Option, { id: 'other', name: t('Other') }]) as Option[],
    };
  }, [dbsAnalyses, t, chosenDbsAnalyses, entityData, includes]);

  const { analyses, otherAnalyses } = useMemo(() => {
    const analyses: Analysis[] = [];
    const otherAnalyses: Analysis[] = [];

    (dbsAnalyses?.length
      ? dbsAnalyses
      : entityData?.dbsAnalyses.length
        ? entityData.dbsAnalyses
        : (includes?.dbsAnalyses as Analysis[]) || []
    ).forEach((it) => {
      if (it.code.startsWith('ADT')) {
        otherAnalyses.push(it);
      } else {
        analyses.push(it);
      }
    });

    return { analyses, otherAnalyses };
  }, [dbsAnalyses, entityData, includes]);

  const decorateValues = useCallback(
    (name: keyof AnalysesFieldsData | undefined, value: any, values: AnalysesFieldsData) => {
      if (name === 'dbsAnalyses') {
        const next = new Set(value || []);
        const prev = new Set(values.dbsAnalyses || []);
        if (prev.has('other') && !next.has('other')) {
          values.otherDbsAnalyses = [];
        } else if (!prev.has('other') && next.has('other') && otherDbsAnalysesOptions[0]) {
          // @ts-ignore
          values.otherDbsAnalyses = [{ id: otherDbsAnalysesOptions[0].id, remarks: null }];
        }
      }

      if (name) {
        return setIn(values, name, value);
      }

      return values;
    },
    [otherDbsAnalysesOptions]
  );

  return (
    <FormFieldset<AnalysesFieldsData> onChange={decorateValues}>
      <Row>
        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            endAdornment={
              onRequestEditAnalyses && (
                <IconButton
                  onClick={onRequestEditAnalyses}
                  type="button"
                  icon="edit"
                  tooltip={t('Edit')}
                />
              )
            }
            options={dbsAnalysesOptions}
            label={t('DBS Analyses')}
            component={FormDropDown}
            name="dbsAnalyses"
            sortOptions={false}
            fast={false}
            onOpen={() => {
              if (!dbsAnalyses?.length) {
                loadAnalyses();
              }
            }}
          />
        </Cell>

        <OtherDbsRow options={otherDbsAnalysesOptions} name="otherDbsAnalyses" />

        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            dummyPlaceholder={DbsSamplesField}
            component={DbsSamplesField}
            name="dbsAnalyses"
            analyses={[...analyses, ...otherAnalyses]}
            diffMode={false}
            fast={false}
          />
        </Cell>

        {!hideNonAnalysesFields && (
          <>
            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={ResourceFormDropdown}
                label={t('Laboratory')}
                name="dbsLabsId"
                resource="labs"
                single
                fast
                resourceReducer={labsReducer}
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={FormSwitch}
                label={t('Expedited Analysis')}
                name="dbsExpedited"
                fast
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field component={FormSwitch} label={t('Long-Term Storage')} name="dbsLts" fast />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                label={t('Analysis Remarks')}
                component={FormTextfield}
                name="dbsComments"
                textarea
              />
            </Cell>
          </>
        )}
      </Row>
    </FormFieldset>
  );
};

export default DbsFields;
