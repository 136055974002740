import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import useLabelsSchema from 'common/components/Labels/useLabelsSchema';
import {
  CountryRequired,
  useAddressSchema,
} from 'core/components/AddressesFields/useAddressesFieldsSchema';
import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import useDateRangeSchema from 'core/hooks/useDateRangeSchema';
import usePermission from 'core/hooks/usePermission';
import useTimeRangeSchema from 'core/hooks/useTimeRangeSchema';
import useAnalysesFieldsSchema from 'planning/components/AnalysesFields/useAnalysesFieldsSchema';
import useOfficersSchema from 'planning/components/OfficersFieldset/useOfficersSchema';
import useSettingsSchema from 'planning/components/SettingsTab/useSettingsSchema';
import useTeamMembersSchema from 'planning/components/TeamTab/useTeamMembersSchema';
import useDateToBufferSchema from 'planning/hooks/useDateBufferSchema';
import usePlannedAtSchema from 'planning/hooks/usePlannedAtSchema';

const tabMeta = getTabMeta<'detail' | 'settings' | 'team'>();

const useTestSchema = ({ disableAdvancedFeatures } = { disableAdvancedFeatures: false }) => {
  const { t } = useTranslation();
  const canEditDetails = usePermission('missions:patch[actions:details]');
  const analysesSchema = useAnalysesFieldsSchema('detail', !canEditDetails);
  const dateRangeSchema = useDateRangeSchema();
  const [timeFromSchema, timeToSchema] = useTimeRangeSchema();
  const teamMembersSchema = useTeamMembersSchema('team');
  const officersSchema = useOfficersSchema();
  const advancedDcoAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const settingsSchema = useSettingsSchema('settings');
  const labelsSchema = useLabelsSchema();
  const plannedAt = usePlannedAtSchema();
  const { dateToBufferSchema, dateFromBufferSchema } = useDateToBufferSchema();
  const addressFields = useAddressSchema(
    {
      countryRequired: CountryRequired.NEVER,
    },
    ['detail']
  );

  return useMemo(
    () =>
      Yup.object({
        missionsId: Yup.number()
          .nullable()
          .test('oneOfRequired', t('This field is required'), function (value) {
            const isRequired = this.parent.code === null;
            return !isRequired || !!value;
          })
          .meta(tabMeta('detail'))
          .default(null),
        missionsExternalId: Yup.string().nullable().max(50).meta(tabMeta('detail')).default(null),
        code: Yup.string()
          .nullable()
          .test('oneOfRequired', t('This field is required'), function (value) {
            const isRequired = this.parent.missionsId === null && !this.parent.generateCode;
            return !isRequired || !!value;
          })
          .max(25)
          .meta(tabMeta('detail'))
          .default(null),
        generateCode: Yup.boolean().meta(tabMeta('detail')).default(false),
        timeZonesId: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        assigneesId: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        athletesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        federationsName: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        federationsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        debtorsName: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        debtorsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        customersName: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        customersId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        agent: Yup.string().nullable().max(20).meta(tabMeta('detail')).default(null),
        sampleAuthoritiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        initiatorAuthoritiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        externalId: Yup.string().nullable().max(50).meta(tabMeta('detail')).default(null),
        testSituationsId: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        poolsId: Yup.number()
          .nullable()
          .when('testSituationsId', {
            is: (value: number) => value === 2,
            then: (schema) => schema.required(),
          })
          .meta(tabMeta('detail'))
          .default(null),
        oneHourSlot: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        sportsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        sportDisciplinesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        club: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        backNumber: Yup.string()
          .test('onlyNumbers', t('Should have only digits'), (val) => !val || /^\d+$/.test(val))
          .nullable()
          .meta(tabMeta('detail'))
          .default(null),
        team: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        testAuthority: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        resultAuthority: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        generalComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        internalComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        drawComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        invoiceComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        accommodationComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        eventName: Yup.string().nullable().max(200).meta(tabMeta('detail')).default(null),
        dateRange: dateRangeSchema.meta(tabMeta('detail')).required(),
        timeFrom: timeFromSchema.meta(tabMeta('detail')),
        timeTo: timeToSchema.meta(tabMeta('detail')),
        dateFromBuffer: dateFromBufferSchema.meta(tabMeta('detail')),
        dateToBuffer: dateToBufferSchema.meta(tabMeta('detail')),
        plannedAt: plannedAt.meta(tabMeta('detail')),
        publishAt: Yup.date()
          .nullable()
          .test(
            'outside-range',
            t('Publish At cannot be later than midnight on the Date From'),
            function (value) {
              if (!value) return true;
              const range: {
                from: Date;
                to: Date;
              } | null = this.parent.dateRange;

              if (!range) return true;

              return (
                DateTime.fromJSDate(range.from).diff(DateTime.fromJSDate(value), 'days').days >= 0
              );
            }
          )
          .meta(tabMeta('detail'))
          .default(null),
        race: Yup.string().nullable().max(100).meta(tabMeta('detail')).default(null),
        place: Yup.string().nullable().max(100).meta(tabMeta('detail')).default(null),
        ...(!disableAdvancedFeatures && advancedDcoAssignmentEnabled ? teamMembersSchema : {}),
        ...(!advancedDcoAssignmentEnabled || disableAdvancedFeatures ? officersSchema : {}),

        // Location
        createLocation: Yup.boolean().meta(tabMeta('detail')).default(false),
        locationsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        locationName: Yup.string()
          .nullable()
          .when('createLocation', {
            is: true,
            then: (schema) => schema.required(),
          })
          .meta(tabMeta('detail'))
          .default(null),

        costsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        invoicingCodesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        plannedGender: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        billableByDco: Yup.boolean().meta(tabMeta('detail')).default(true),
        billableToCustomer: Yup.boolean().meta(tabMeta('detail')).default(true),
      })
        .concat(labelsSchema.meta(tabMeta('detail')))
        .concat(analysesSchema.meta(tabMeta('detail')))
        .concat(settingsSchema.meta(tabMeta('settings')))
        .concat(addressFields.meta(tabMeta('detail'))),
    [
      analysesSchema,
      addressFields,
      t,
      dateRangeSchema,
      timeFromSchema,
      timeToSchema,
      dateFromBufferSchema,
      dateToBufferSchema,
      plannedAt,
      disableAdvancedFeatures,
      advancedDcoAssignmentEnabled,
      teamMembersSchema,
      settingsSchema,
      officersSchema,
      labelsSchema,
    ]
  );
};

export type TestFormData = Yup.TypeOf<ReturnType<typeof useTestSchema>>;
export type ValidTestFormData = Yup.Asserts<ReturnType<typeof useTestSchema>>;

export default useTestSchema;
