import { AnalysesFieldsData } from './useAnalysesFieldsSchema';

interface OutputAnalysis {
  analysesId: number;
  otherAnalysisRemarks: null | string;
}

const analysesFieldsOutputMapping = (fd: AnalysesFieldsData) => {
  return {
    bloodAnalyses: [
      ...fd.bloodAnalyses
        .filter((i) => i !== 'other')
        .map((id) => ({ analysesId: id, otherAnalysisRemarks: null }) as OutputAnalysis),
      ...(fd.otherBloodAnalyses || []).map(({ id, remarks }) => ({
        analysesId: id,
        otherAnalysisRemarks: remarks,
      })),
    ],
    urineAnalyses: [
      ...fd.urineAnalyses
        .filter((i) => i !== 'other')
        .map((id) => ({ analysesId: id, otherAnalysisRemarks: null }) as OutputAnalysis),
      ...(fd.otherUrineAnalyses || []).map(({ id, remarks }) => ({
        analysesId: id,
        otherAnalysisRemarks: remarks,
      })),
    ],
    dbsAnalyses: [
      ...fd.dbsAnalyses
        .filter((i) => i !== 'other')
        .map((id) => ({ analysesId: id, otherAnalysisRemarks: null }) as OutputAnalysis),
      ...(fd.otherDbsAnalyses || []).map(({ id, remarks }) => ({
        analysesId: id,
        otherAnalysisRemarks: remarks,
      })),
    ],
    otherBloodAnalyses: undefined,
    otherUrineAnalyses: undefined,
    otherDbsAnalyses: undefined,
  } as const;
};

export default analysesFieldsOutputMapping;
