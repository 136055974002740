import { TFunction } from 'i18next';

import { ApiCallErrorAction, ApiCallSuccessAction } from 'core/actions';
import { t } from 'core/i18n';
import ApplicationContent from 'core/models/AppContent';
import { switchSettings } from 'planning/components/SettingsTab/useSwitchSettings';
import {
  SampleCodeType,
  getSampleCodeSchema,
} from 'settings/containers/ControlSettingsPage/useSchema';

import { SettingsActions } from './actions';
import { PASSWORD_DEFAULT } from './containers/AdamsSettingsPage/useSchema';
import { SAMPLE_CODE_TYPES } from './containers/ControlSettingsPage/Defaults/components/SampleCodesPaper';
import {
  ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS,
  VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS,
} from './containers/ControlSettingsPage/Features/AutomaticApproval';
import { autoArchiveOptions } from './containers/ControlSettingsPage/Features/useAutoArchiveItems';
import Setting from './models/Setting';

export type SettingsFormData = {
  [key: string]: string | boolean | number | null | SampleCodeType;
};

export type ApplicationContentsFormData = {
  [key: string]: ApplicationContent[];
};

export type CocContentForLanguage = {
  cocEmailSender: { value: string; id?: number };
  cocEmailReplyTo: { value: string; id?: number };
  cocEmailSubject: { value: string; id?: number };
  cocEmailText: { value: string; id?: number };
  languagesId: number | null;
};

export type DcfContentForLanguage = {
  dcfEmailSender: { value: string; id?: number };
  dcfEmailReplyTo: { value: string; id?: number };
  dcfEmailSubject: { value: string; id?: number };
  dcfEmailText: { value: string; id?: number };
  dcfEmailTextDco: { value: string; id?: number };
  languagesId: number | null;
};

export interface SettingsState {
  controlSettings?: SettingsFormData;
  adamsSettings?: SettingsFormData;
}

const initialState: SettingsState = {
  controlSettings: undefined,
  adamsSettings: undefined,
};

// All settings that are only booleans should be specified here for correct mapping
// TODO: Ideally we would only specify settings once and everything would be generated dynamically - types, mapping, fields...
const booleanControlSettings = new Set([
  // Control Settings - Features
  'enableCancelControl',
  'enableAddressCopying',
  'enableCoachDoctor',
  'enableAthleteAutoSetAsMaster',
  'filterAthletePersonalData',
  'requireLocking',
  'hideAthleteDocumentNumber',
  'athleteNationalityMandatory',
  'enableLockTimer',
  'allowDcoDetailEdit',
  'showArchivedToDcos',
  'arrivalDelayCheckEnabled',
  'showAnalysesToAssignedDco',

  // Control Settings - Additional Values
  'showCardAddressEndDate',
  'enableTeamAndBackNumber',
  'enablePoolInTheApp',
  'enableTue',
  'enableVenipuncture',
  'enableBloodDcoSignature',
  'enableAthletesConsent',
  'enableAthleteAddressInTheApp',
  'athleteZipMandatory',
  'enableSocialSecurityNumber',
  'cocDriverMandatory',
  'tdpFederationMandatory',
  'autoArchiveTestShipped',
  'hideRestrictedAccessInUar',
  'enableAutoChangeTestStatusToUnsuccessful',
  'enablePreviousDeclarations',
  'medicationNameMandatoryOnly',

  // Defaults for mission/test settings
  ...switchSettings.control.map((it) => `defaultFor${it.charAt(0).toUpperCase()}${it.slice(1)}`),
  ...switchSettings.dcf.map((it) => `defaultFor${it.charAt(0).toUpperCase()}${it.slice(1)}`),
]);

// All settings that are only booleans should be specified here for correct mapping
// TODO: Ideally we would only specify settings once and everything would be generated dynamically
const booleanAdamsSettings = new Set(['enable']);

export const protectedAdamsSettings = new Set([
  'adamsPassword',
  'answerToSecurityQuestion1',
  'answerToSecurityQuestion2',
  'answerToSecurityQuestion3',
]);

export default function reducer(
  state = initialState,
  action:
    | SettingsActions
    | ApiCallSuccessAction<'LOAD_CONTROL_SETTINGS_SUCCESS'>
    | ApiCallErrorAction<'LOAD_CONTROL_SETTINGS_ERROR'>
    | ApiCallSuccessAction<'LOAD_ADAMS_SETTINGS_SUCCESS'>
    | ApiCallErrorAction<'LOAD_ADAMS_SETTINGS_ERROR'>
    | ApiCallSuccessAction<'LOAD_CLIENT_LOGO_SUCCESS'>
): SettingsState {
  switch (action.type) {
    case 'LOAD_CONTROL_SETTINGS_SUCCESS':
      const controlSettingData = action.payload.response.data;
      const transformedSettings: SettingsFormData = {};
      const sampleCodeSchema = getSampleCodeSchema(t as TFunction);
      // creating this, because without this nested validation is not possible
      controlSettingData.forEach((item: Setting) => {
        if (SAMPLE_CODE_TYPES.includes(item.key)) {
          transformedSettings[item.key] =
            typeof item.value === 'object' && item.value !== null
              ? sampleCodeSchema.cast({
                  ...item.value,
                  enabled: true,
                })
              : null;
          return;
        }

        transformedSettings[`value_${item.key}`] = (item.value && item.value.toString()) || null;
        transformedSettings[`enabled_${item.key}`] = booleanControlSettings.has(item.key)
          ? item.value === '1'
          : !!item.value;
        transformedSettings[`id_${item.key}`] = item.id.toString();
      });

      [
        'defaultFederationsId',
        'defaultDebtorsId',
        'defaultCustomersId',
        'tdpClassPreference',
        'defaultTestAuthority',
        'defaultResultAuthority',
        'defaultBloodManufacturer',
        'defaultUrineManufacturer',
        'defaultDbsManufacturer',
        'defaultSampleCollectionAuthority',
        'defaultForDcc',
        'defaultAvailabilityCategoriesId',
        'defaultCocShippingType',
        'defaultCocLaboratory',
        'defaultUrineLaboratory',
        'defaultBloodLaboratory',
        'defaultDbsLaboratory',
        'covidSrf',
        'arrivalDelayMax',
        'lockTimerTimeout',
        'testPublishOffset',
        'sessionLifetime',
        'defaultForAdamsAthleteLevelsId',
        'invoiceAutoApprovalDurationDays',

        ...autoArchiveOptions.filter((o) => o.type === 'AGE').map((o) => o.key),
      ].forEach((key: string) => {
        if (transformedSettings[`value_${key}`]) {
          transformedSettings[`value_${key}`] = Number(transformedSettings[`value_${key}`]);
        }
      });

      transformedSettings['enabled_defaultForUseCustomFileTemplate'] =
        !!transformedSettings[`value_defaultForFileTemplatesId`];

      transformedSettings['enabled_defaultForUseAdamsAthleteLevel'] =
        !!transformedSettings[`value_defaultForAdamsAthleteLevelsId`];

      transformedSettings['value_defaultForFileTemplatesId'] =
        transformedSettings[`value_defaultForFileTemplatesId`] || 'default';

      transformedSettings[ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS] =
        !!transformedSettings[VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS];

      return {
        ...state,
        controlSettings: transformedSettings,
      };
    case 'LOAD_CONTROL_SETTINGS_ERROR':
    case 'UNLOAD_CONTROL_SETTINGS':
      return {
        ...state,
        controlSettings: undefined,
      };

    case 'LOAD_ADAMS_SETTINGS_SUCCESS':
      const adamsSettingData = action.payload.response.data as {
        key: string;
        value: string | null;
      }[];

      return {
        ...state,
        adamsSettings: Object.fromEntries(
          adamsSettingData.map(({ key, value }) => {
            if (booleanAdamsSettings.has(key)) {
              return [key, value === '1'];
            } else if (key === 'adamsCustomPoolMapping') {
              return [
                key,
                value
                  ? Object.entries(JSON.parse(value)).map(([modoc, adams]) => ({
                      modoc,
                      adams,
                    }))
                  : [],
              ];
            } else if (protectedAdamsSettings.has(key)) {
              return [key, PASSWORD_DEFAULT];
            } else {
              return [key, value];
            }
          })
        ),
      };
    case 'LOAD_ADAMS_SETTINGS_ERROR':
    case 'UNLOAD_ADAMS_SETTINGS':
      return {
        ...state,
        adamsSettings: undefined,
      };

    default:
      return state;
  }
}
