import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import { FullRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useBoolClientOption } from 'core/hooks/useClientOption';

const LabelsField: FC<{ fieldName?: string }> = ({ fieldName = 'labels' }) => {
  const { t } = useTranslation();
  const labelsEnabled = useBoolClientOption('enableLabels');

  if (!labelsEnabled) {
    return null;
  }

  return (
    <FullRowCell>
      <Field
        label={t('Labels')}
        component={ResourceFormDropdown}
        name={fieldName}
        resource="labels"
        fast={false}
      />
    </FullRowCell>
  );
};

export default LabelsField;
