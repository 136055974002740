import Country from 'core/models/Country';
import Disability from 'core/models/Disability';
import FileType from 'core/models/FileType';
import { Language } from 'core/models/Language';
import { Locale } from 'core/models/Locale';
import { definition, EntityMeta, getStandardApi, getStandardUrls } from 'core/module';

import Analysis from './models/Analysis';
import { AuthorityDetail, AuthorityGrid } from './models/Authority';
import AvailabilityCategory from './models/AvailabilityCategory';
import Courier from './models/Courier';
import Dca from './models/Dca';
import DocumentType from './models/DocumentType';
import Federation from './models/Federation';
import InvoicingCode, { InvoicingCodeRequest } from './models/InvoicingCode';
import { Label, LabelGrid } from './models/Label';
import Laboratory from './models/Laboratory';
import Location from './models/Location';
import PaymentMethod from './models/PaymentMethod';
import Pool from './models/Pool';
import SampleManufacturer from './models/SampleManufacturer';
import Sport from './models/Sport';
import SportDiscipline from './models/SportDiscipline';
import TdpClass from './models/TdpClass';
import TestCancellationReason from './models/TestCancellationReason';
import TestReturnReason from './models/TestReturnReason';
import TestReturnResolution from './models/TestReturnResolution';
import UAInternalReason from './models/UAInternalReason';

type T = (m: string) => string;

export const listsEntities = {
  analysis: definition<Analysis>('analyses', {
    name: (t: T) => t('Analysis'),
    listName: (t: T) => t('Analyses'),
  }),
  authority: {
    ...definition<AuthorityDetail, AuthorityGrid>('authorities', {
      name: (t: T) => t('Authority'),
      listName: (t: T) => t('Authorities'),
    }),
    api: () => ({
      ...getStandardApi('authorities'),
      image: (id: number) => `/authorities/${id}/image`,
    }),
  },
  fileType: definition<FileType>('file-types', {
    name: (t: T) => t('File Type'),
    listName: (t: T) => t('File Types'),
    permission: 'fileTypes',
  }),
  documentType: definition<DocumentType>('document-types', {
    name: (t: T) => t('Document Type'),
    listName: (t: T) => t('Document Types'),
    permission: 'documentTypes',
  }),
  country: definition<Country>('countries', {
    name: (t: T) => t('Country'),
    listName: (t: T) => t('Countries'),
  }),
  disability: definition<Disability>('disabilities', {
    name: (t: T) => t('Impairment'),
    listName: (t: T) => t('Impairments'),
  }),
  dca: definition<Dca>('dcas', {
    name: (t: T) => t('Doping Control Assistant'),
    listName: (t: T) => t('Doping Control Assistants'),
  }),
  availabilityCategory: definition<AvailabilityCategory>('availability-categories', {
    name: (t: T) => t('Availability Category'),
    listName: (t: T) => t('Availability Categories'),
    permission: 'availabilityCategories',
  }),
  label: {
    ...definition<LabelGrid, Label>('labels', {
      name: (t: T) => t('Labels'),
      listName: (t: T) => t('Labels'),
      permission: 'labels',
    }),
    urls: () => getStandardUrls('labels'),
    api: () => ({
      ...getStandardApi('labels'),
    }),
  },
  language: definition<Language>('languages', {
    name: (t: T) => t('Language'),
    listName: (t: T) => t('Languages'),
  }),
  locale: definition<Locale>('locales', {
    name: (t: T) => t('Locale'),
    listName: (t: T) => t('Locales'),
  }),
  paymentMethod: definition<PaymentMethod>('payment-methods', {
    name: (t: T) => t('Payment Method'),
    listName: (t: T) => t('Payment Methods'),
    permission: 'paymentMethods',
  }),
  courier: definition<Courier>('couriers', {
    name: (t: T) => t('Courier'),
    listName: (t: T) => t('Couriers'),
  }),
  federation: definition<Federation>('federations', {
    name: (t: T) => t('Federation'),
    listName: (t: T) => t('Federations'),
  }),
  lab: definition<Laboratory>('labs', {
    name: (t: T) => t('Laboratory'),
    listName: (t: T) => t('Laboratories'),
  }),
  location: definition<Location>('locations', {
    name: (t: T) => t('Location'),
    listName: (t: T) => t('Locations'),
  }),
  invoicingCode: definition<InvoicingCode, InvoicingCode, InvoicingCodeRequest>('invoicing-codes', {
    name: (t: T) => t('Invoicing Code'),
    listName: (t: T) => t('Invoicing Codes'),
    permission: 'invoicingCodes',
  }),
  pool: definition<Pool>('pools', {
    name: (t: T) => t('Pool'),
    listName: (t: T) => t('Pools'),
  }),
  // TODO: Sample Manufacturers TODO: permissions
  sampleManufacturer: {
    ...definition<SampleManufacturer>('samples/manufacturers', {
      name: (t: T) => t('Sample Manufacturer'),
      listName: (t: T) => t('Sample Manufacturers'),
      permission: 'sampleManufacturers',
    }),
    urls: () => getStandardUrls('samples-manufacturers'),
  },
  sport: definition<Sport>('sports', {
    name: (t: T) => t('Sport'),
    listName: (t: T) => t('Sports'),
  }),
  sportDiscipline: {
    ...definition<SportDiscipline>('sports/disciplines', {
      name: (t: T) => t('Sport Discipline'),
      listName: (t: T) => t('Sport Disciplines'),
      permission: 'sportDisciplines',
    }),
    urls: () => getStandardUrls('sports-disciplines'),
  },

  // TODO: urls
  tdpClass: {
    ...definition<TdpClass>('tdps/classes', {
      name: (t: T) => t('Distribution Plan Class'),
      listName: (t: T) => t('Distribution Plan Classes'),
      permission: 'tdpClasses',
    }),
    urls: () => getStandardUrls('tdps-classes'),
  },

  testCancellationReason: {
    ...definition<TestCancellationReason>('tests/cancellation/reasons', {
      name: (t: T) => t('Test Cancellation Reason'),
      listName: (t: T) => t('Test Cancellation Reasons'),
      permission: 'testCancellationReasons',
    }),
    urls: () => getStandardUrls('tests-cancellation-reasons'),
  },
  // TODO: Permissions OR URLS
  testReturnReason: {
    ...definition<TestReturnReason>('tests/returns/reasons', {
      name: (t: T) => t('Test Return Reason'),
      listName: (t: T) => t('Test Return Reasons'),
      permission: 'testReturnReasons',
    }),
    urls: () => getStandardUrls('tests-returns-reasons'),
  },
  testReturnResolution: {
    ...definition<TestReturnResolution>('tests/returns/resolutions', {
      name: (t: T) => t('Test Return Resolution'),
      listName: (t: T) => t('Test Return Resolutions'),
      permission: 'testReturnResolutions',
    }),
    urls: () => getStandardUrls('tests-returns-resolutions'),
  },
  testUaInternalReason: {
    ...definition<UAInternalReason>('tests/ua/reasons', {
      name: (t: T) => t('UA Internal Reason'),
      listName: (t: T) => t('UA Internal Reasons'),
      permission: 'testUaInternalReasons',
    }),
    urls: () => getStandardUrls('tests-ua-reasons'),
  },
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof listsEntities, EntityMeta> = listsEntities;

export type K = keyof typeof listsEntities;
