import { ICellRendererParams } from 'ag-grid-community';
import React, { ReactNode } from 'react';

import { FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';

import TooltipCellRenderer from '../../CellRenderers/TooltipCellRenderer';

export interface IconCellParams {
  content?: (data: any) => ReactNode;
  color?: (data: any) => string;
  size?: number;
  component?: (data: any) => ReactNode;
  name?: string;
  hidden?: (data: any) => boolean;
}
export interface IconCellRendererParams {
  params: ICellRendererParams & { icons?: IconCellParams[] };
}

const IconCellRenderer = ({ params }: IconCellRendererParams) => {
  return (
    <FlexRow>
      {params.icons?.map((icon, index) => {
        if (icon?.hidden?.(params.data)) return null;
        if (icon.component)
          return <React.Fragment key={index}>{icon.component(params)}</React.Fragment>;

        return (
          <TooltipCellRenderer
            content={(params) => icon?.content?.(params)}
            params={params}
            key={index}
            width="1.75rem"
          >
            <MaterialIcon
              icon={icon.name || ''}
              color={icon?.color?.(params.data)}
              size={icon.size}
            />
          </TooltipCellRenderer>
        );
      })}
    </FlexRow>
  );
};

export default IconCellRenderer;
