import { DateTime } from 'luxon';
import { useCallback } from 'react';

import useLabelsOutputMapping from 'common/components/Labels/useLabelsOutputMapping';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { AnalysesFieldsData } from 'planning/components/AnalysesFields';
import analysesFieldsOutputMapping from 'planning/components/AnalysesFields/analysesFieldsOutputMapping';
import useOfficersOutputMapping from 'planning/components/OfficersFieldset/useOfficersOutputMapping';
import useSettingsOutputMapping from 'planning/components/SettingsTab/useSettingsOutputMapping';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';
import useTeamOutputMapping from 'planning/components/TeamTab/useTeamOutputMapping';
import Test from 'planning/models/Test';

import { TestFormData } from './useTestSchema';

const useTestOutputMapping = (
  { disableAdvancedFeatures, disableTeamMembers } = {
    disableAdvancedFeatures: false,
    disableTeamMembers: false,
  }
) => {
  const advancedDcoAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const teamOutputMapping = useTeamOutputMapping();
  const officersOutputMapping = useOfficersOutputMapping(disableTeamMembers);
  const settingsOutputMapping = useSettingsOutputMapping();
  const labelsOutputMapping = useLabelsOutputMapping();

  return useCallback(
    (formData: TestFormData, mode: 'create' | 'edit' | 'clone', entity?: Test) => {
      // https://cannypack.atlassian.net/browse/MODFE-2774
      // ignore address fields id, type
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dateRange, generateCode, code, id, type, ...testData } = formData;
      const start = dateRange?.from || null;
      const end = dateRange?.to || null;

      // Test situation cannot be changed
      if (mode === 'edit') {
        testData.testSituationsId = null;
      }

      // @ts-ignore
      if (testData && testData['athlete.profile']) {
        // @ts-ignore

        delete testData['athlete.profile'];
      }

      return {
        ...testData,

        missionsId: formData.missionsId || null,
        missionsCode: generateCode ? null : code,

        federationsName: formData.federationsId ? null : formData.federationsName,
        federationsId: formData.federationsId || null,

        debtorsName: formData.debtorsId ? null : formData.debtorsName,
        debtorsId: formData.debtorsId || null,

        customersName: formData.customersId ? null : formData.customersName,
        customersId: formData.customersId || null,

        useCustomFileTemplate: undefined,
        useAdamsAthleteLevel: undefined,

        archived: mode === 'edit' ? !!entity?.archived : false,

        dateFrom: start ? transformDateToString(start, 'DATE') : null,
        dateTo: end ? transformDateToString(end, 'DATE') : null,

        timeFrom: formData.timeFrom ? transformDateToString(formData.timeFrom, 'TIME') : null,
        timeTo: formData.timeTo ? transformDateToString(formData.timeTo, 'TIME') : null,

        // TODO
        dateFromBuffer: formData.dateToBuffer !== null ? Number(formData.dateFromBuffer) : null,
        // TODO: Find out why this does not get cast to number as formik/use new prop parse when it lands in version we can update to
        dateToBuffer: formData.dateToBuffer !== null ? Number(formData.dateToBuffer) : null,
        plannedAt: formData.plannedAt ? transformDateToString(formData.plannedAt) : null,
        publishAt: formData.publishAt
          ? transformDateToString(formData.publishAt)
          : start
            ? transformDateToString(DateTime.fromJSDate(start).startOf('day').minus({ day: 1 }))
            : null,

        oneHourSlot: formData.oneHourSlot,
        costsId: formData.costsId,
        invoicingCodesId: formData.invoicingCodesId,

        ...(advancedDcoAssignmentEnabled && !disableAdvancedFeatures
          ? teamOutputMapping({
              // We are sure those are defined when assignment is enabled as input/schema has them
              invitedMembers: (formData.invitedMembers || []) as TeamMemberData[],
              assignedMembers: (formData.assignedMembers || []) as TeamMemberData[],
              notifyOfTeamUpdates: formData.notifyOfTeamUpdates!,
              showAthleteToDco: formData.showAthleteToDco!,
            })
          : officersOutputMapping({
              invitedMembers: (formData.invitedMembers || []) as TeamMemberData[],
              assignedMembers: (formData.assignedMembers || []) as TeamMemberData[],
              dcos: formData.dcos || [],
              bcos: formData.bcos || [],
              chaperones: formData.chaperones || [],
              leadDcosId: formData.leadDcosId || null,
            })),

        locationName: testData.createLocation ? testData.locationName : undefined,
        locationsId: testData.createLocation ? null : testData.locationsId,
        ...labelsOutputMapping(formData),
        ...analysesFieldsOutputMapping(formData as unknown as AnalysesFieldsData),
        ...settingsOutputMapping(formData),
      } as const;
    },
    [
      advancedDcoAssignmentEnabled,
      teamOutputMapping,
      officersOutputMapping,
      disableAdvancedFeatures,
      settingsOutputMapping,
      labelsOutputMapping,
    ]
  );
};

export type TestRequestData = ReturnType<ReturnType<typeof useTestOutputMapping>>;

export default useTestOutputMapping;
