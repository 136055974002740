import { AxiosResponse } from 'axios';
import isEqual from 'lodash/isEqual';

import Event from 'app/models/Event';
import Profile from 'app/models/Profile';
import { CustomColumnConfiguration } from 'core/containers/DataGrid/Toolbar/CustomizeViews/tabs/Columns';
import BaseModel from 'core/models/Base';
import { ClientOption, Permission } from 'core/models/CoreProfile';

import {
  ApiCallErrorAction,
  ApiCallSuccessAction,
  CoreActions,
  DeleteViewAction,
  ImportDataAction,
  PREFIX_DELETE_VIEW,
  PREFIX_EXPORT_DATA_ENDPOINT,
  PREFIX_IMPORT_DATA_ENDPOINT,
  PREFIX_SAVE_VIEW,
  SavedDataGridParams,
  SaveViewAction,
  TYPE_RENEW_SESSION,
} from './actions';
import { FilterValue } from './containers/DataGrid';
import { transformDateToString } from './effects/apiCall/dateTimeTransformations';
import { ApiQueryOptions } from './hooks/useApiCall';
import { UserView } from './models/UserView';

export type ResetPwdFormData = {
  password: string | undefined;
};

export enum ApiQueryStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

// Generic API data Type object
export interface ApiQuery<T = unknown> {
  /**
   * Query status
   * "idle" - initialized but waiting for "fire" - conditionally loaded resources
   * "loading" - fired request and waiting for response
   * "error" - request is done with error
   * "success" - request is done successfully
   */
  status: ApiQueryStatus;
  /**
   * Response data
   */
  data?: T;
  /**
   * Response Error
   */
  error?: Error | string;
  /**
   * Indicator for saga effect - not trigger twice
   * status could be "loading" because its initial status from "autoload" option
   * but it was not fired yet
   */
  isFired?: boolean;
  /**
   * Original response (in case of specific processing)
   */
  response?: AxiosResponse;
  options: ApiQueryOptions;
}

export interface CoreState {
  sessionRenewInProgress: boolean;
  user?: Profile;
  language?: string;
  languagesId?: number;
  locale?: string;
  localesId?: number;
  i18nInitialized: boolean;
  twoFactorLoginMode: boolean;
  loginInProgress: boolean;
  logoutInProgress: boolean;
  prevPathname?: string;
  validCode?: string;
  validateCodeInProgress: boolean;
  restoreInProgress: boolean;
  restoreIsSubmitted: boolean;
  resetInProgress: boolean;
  resetIsSubmitted: boolean;
  activationInProgress: boolean;
  activateIsSubmitted: boolean;
  exportInProgress: boolean;
  importInProgress: boolean;
  loadingResourcesInProgress: boolean;
  entityData?: BaseModel;
  entityDataSource?: string;
  entitySaveInProgress: boolean;
  dialogSaveInProgress: boolean;
  entityDataLoading: boolean;
  formResources?: { [resourceKey: string]: unknown };
  twoFactorSecret?: string;
  tabErrors: string[];
  dataGridColumnConfiguration?: {
    [endpoint: string]: CustomColumnConfiguration;
  };
  dataGridEntityParams?: SavedDataGridParams;
  viewSavingInProgress: boolean;
  viewDeletingInProgress: boolean;
  /** Use to externally open rendered `DropDown` by its id */
  openDropdownId?: string;
  socket: {
    connected: boolean;
  };
  notifications: {
    events?: Event[];
    unread: number;
  };
  updateAvailable: boolean;
  isLoggedIn?: true;
  apiQueries: Record<string, ApiQuery>;
}

export const initialState = {
  openItems: {},
  sessionRenewInProgress: false,
  i18nInitialized: false,
  twoFactorLoginMode: false,
  loginInProgress: false,
  logoutInProgress: false,
  validateCodeInProgress: false,
  restoreInProgress: false,
  restoreIsSubmitted: false,
  resetInProgress: false,
  resetIsSubmitted: false,
  activationInProgress: false,
  activateIsSubmitted: false,
  exportInProgress: false,
  importInProgress: false,
  loadingResourcesInProgress: false,
  entityDataLoading: false,
  entitySaveInProgress: false,
  dialogSaveInProgress: false,
  tabErrors: [],
  fileTypes: undefined,
  fileAssignment: undefined,
  fileAttachments: undefined,
  fileDetail: undefined,
  viewSavingInProgress: false,
  viewDeletingInProgress: false,
  socket: {
    connected: false,
  },
  notifications: {
    unread: 0,
  },
  updateAvailable: false,
  apiQueries: {},
};

export default function reducer(
  state: CoreState = initialState,
  action:
    | CoreActions
    | ApiCallSuccessAction<'LOAD_USERS_SUCCESS'>
    | ApiCallErrorAction<'LOAD_USERS_ERROR'>
    | ApiCallSuccessAction<'LOAD_PROFILE_SUCCESS'>
    | ApiCallSuccessAction<'CREATE_ENTITY_SUCCESS'>
    | ApiCallSuccessAction<'EDIT_ENTITY_SUCCESS'>
    | ApiCallErrorAction<'LOAD_ENTITY_ERROR'>
    | ApiCallErrorAction<'CREATE_ENTITY_ERROR'>
    | ApiCallErrorAction<'EDIT_ENTITY_ERROR'>
    | ApiCallSuccessAction<'SUBSCRIBE_2FA_SUCCESS'>
    | ApiCallErrorAction<'FETCH_FILE_TYPES_SUCCESS'>
    | ApiCallSuccessAction<'FETCH_FILE_TYPES_ERROR'>
    | ApiCallSuccessAction<'SAVE_FILE_DOCUMENT_SUCCESS'>
    | ApiCallErrorAction<'SAVE_FILE_DOCUMENT_ERROR'>
    | ApiCallSuccessAction<'BULK_UPLOAD_FILES_SUCCESS'>
    | ApiCallErrorAction<'BULK_UPLOAD_FILES_ERROR'>
    | ApiCallSuccessAction<'SEND_EMAIL_SUCCESS'>
    | ApiCallErrorAction<'SEND_EMAIL_ERROR'>
    | ApiCallSuccessAction<'BULK_DOWNLOAD_SUCCESS'>
    | ApiCallErrorAction<'BULK_DOWNLOAD_ERROR'>
    | ApiCallSuccessAction<'UPLOAD_IMAGE_SUCCESS'>
    | ApiCallErrorAction<'UPLOAD_IMAGE_ERROR'>
    | ApiCallSuccessAction<'SAVE_DIALOG_ENTITY_SUCCESS'>
    | ApiCallErrorAction<'SAVE_DIALOG_ENTITY_ERROR'>
): CoreState {
  switch (action.type) {
    case TYPE_RENEW_SESSION:
      return { ...state, sessionRenewInProgress: true, twoFactorLoginMode: false };

    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        language: action.payload.language,
      };

    case 'I18N_INITIALIZED':
      return {
        ...state,
        i18nInitialized: true,
      };

    case 'SUBSCRIBE_2FA_SUCCESS':
      return {
        ...state,
        twoFactorSecret: action.payload.response.data.secret,
      };

    case 'CLEAR_2FA':
      return { ...state, twoFactorSecret: undefined };

    case 'LOGIN':
      return {
        ...state,
        loginInProgress: true,
      };

    case 'GET_WORKSPACE':
      return state;

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loginInProgress: false,
        isLoggedIn: true,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        loginInProgress: false,
      };

    case 'LOGOUT':
      return {
        ...state,
        logoutInProgress: true,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        user: undefined,
        isLoggedIn: undefined,
        logoutInProgress: false,
        twoFactorLoginMode: false,
        prevPathname: action.payload.clearPrevPathname ? undefined : state.prevPathname,
      };

    case 'LOGOUT_ERROR':
      return {
        ...state,
        logoutInProgress: false,
      };

    case 'SWITCH_LOGIN':
      return {
        ...state,
        twoFactorLoginMode: action.payload.twoFactorMode,
        loginInProgress: false,
      };

    case 'SAVE_PREV_PATHNAME':
      return {
        ...state,
        prevPathname: action.payload.prevPathname,
      };

    case 'VALIDATE_CODE':
      return {
        ...state,
        validCode: undefined,
        validateCodeInProgress: true,
      };

    case 'VALIDATE_CODE_SUCCESS':
      return {
        ...state,
        validCode: action.payload.code,
        validateCodeInProgress: false,
      };

    case 'VALIDATE_CODE_ERROR':
      return {
        ...state,
        validateCodeInProgress: false,
      };

    case 'UNLOCK_ACCOUNT_RESET':
      return {
        ...state,
        validateCodeInProgress: false,
        validCode: undefined,
      };

    case 'RESTORE_PASSWORD':
      return {
        ...state,
        restoreInProgress: true,
      };

    case 'RESTORE_PASSWORD_SUCCESS':
      return {
        ...state,
        restoreInProgress: false,
        restoreIsSubmitted: true,
      };

    case 'RESTORE_PASSWORD_ERROR':
      return {
        ...state,
        restoreInProgress: false,
      };

    case 'RESTORE_PASSWORD_RESET':
      return {
        ...state,
        restoreIsSubmitted: false,
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        resetInProgress: true,
      };

    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        resetInProgress: false,
        resetIsSubmitted: true,
      };

    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        resetInProgress: false,
      };

    case 'RESET_PASSWORD_RESET':
      return {
        ...state,
        resetIsSubmitted: false,
        validCode: undefined,
      };

    case 'ACTIVATE_ACCOUNT':
      return {
        ...state,
        activationInProgress: true,
      };

    case 'ACTIVATE_ACCOUNT_SUCCESS':
      return {
        ...state,
        activationInProgress: false,
        activateIsSubmitted: true,
      };

    case 'ACTIVATE_ACCOUNT_ERROR':
      return {
        ...state,
        activationInProgress: false,
      };

    case 'ACTIVATE_ACCOUNT_RESET':
      return {
        ...state,
        activateIsSubmitted: false,
        validCode: undefined,
      };

    case 'EXPORT_DATA':
      return {
        ...state,
        exportInProgress: true,
      };

    case `${PREFIX_EXPORT_DATA_ENDPOINT}_SUCCESS` as ApiCallSuccessAction['type']:
      return {
        ...state,
        exportInProgress: false,
      };

    case `${PREFIX_EXPORT_DATA_ENDPOINT}_ERROR` as ApiCallErrorAction['type']:
      return {
        ...state,
        exportInProgress: false,
      };

    case 'IMPORT_DATA':
      return {
        ...state,
        importInProgress: true,
      };

    case `${PREFIX_IMPORT_DATA_ENDPOINT}_SUCCESS` as ImportDataAction['type']:
      return {
        ...state,
        importInProgress: false,
      };

    case `${PREFIX_IMPORT_DATA_ENDPOINT}_ERROR` as ImportDataAction['type']:
      return {
        ...state,
        importInProgress: false,
      };

    case 'BOOTSTRAPPED': // load profile on init
    case 'LOAD_PROFILE_SUCCESS': // reload profile when something changed
      const data = { ...action.payload.response.data };

      if (
        Array.isArray(data.permissions) && // < --- failsafe when data are from profile reload
        (!data?.settings ||
          !data?.settings?.translationsLanguages ||
          !data?.settings?.translationsLanguages.length)
      ) {
        // Custom permission case for translations module, we remove the permissions from the user when
        // he doesn't have allowed translations languages, so our permissions abstractions will still work without ugly if statements
        data.permissions = data.permissions.filter(
          (it: Permission) => it.controller !== 'projectTranslations'
        );
      }

      // Transform user RBAC permissions so they are accessible in constant time
      const transformPermissions = (permissions: Permission[]) =>
        permissions.reduce((acc: { [key: string]: Permission }, rule: Permission) => {
          acc[`${rule.controller}:${rule.action}`] = rule;
          return acc;
        }, {});

      const permissionMap = Array.isArray(data.permissions) // < --- failsafe when data are from profile reload
        ? transformPermissions(data.permissions)
        : data.permissions; // <--- and here

      // Transform client options so they are accessible in constant time via key
      const clientOptionsMap = Array.isArray(data.clientOptions) // < --- failsafe when data are from profile reload
        ? data.clientOptions.reduce(
            (acc: { [key: string]: ClientOption }, option: ClientOption) => {
              if (!acc.hasOwnProperty(option.key) || acc[option.key].type !== 'internal') {
                acc[option.key] = option;
              }
              return acc;
            },
            {}
          )
        : data.clientOptions; // <--- and here

      // Transform filter defaults so they are accessible in constant time via entity
      const filterDefaultsMap = Array.isArray(data.filters) // < --- failsafe when data are from profile reload
        ? data.filters.reduce(
            (acc: { [entity: string]: { [key: string]: FilterValue } }, option: UserView) => {
              const optionData = { ...option.data.filters };
              for (const [key, value] of Object.entries(optionData)) {
                // `value` can be an instance of Date (or array of Dates) because we are doing a conversion
                // from strings to Dates that match regex pattern in `/effects/apiCall/index.ts`
                if ((value as any) instanceof Date) {
                  optionData[key] = transformDateToString(value as any, 'DATE');
                } else if (Array.isArray(value)) {
                  optionData[key] = value.map((v) =>
                    (v as any) instanceof Date ? transformDateToString(v as any, 'DATE') : v
                  );
                }
              }
              acc[option.entity] = optionData;
              return acc;
            },
            {}
          )
        : data.clientOptions; // <--- and here
      delete data.filters;

      return {
        ...state,
        user: {
          ...data,
          permissions: permissionMap,
          clientOptions: clientOptionsMap,
          filterDefaults: filterDefaultsMap,
          ...(data?.originUser
            ? {
                originUser: {
                  ...data.originUser,
                  permissions: Array.isArray(data.originUser.permissions) // < --- failsafe when data are from profile reload
                    ? transformPermissions(data.originUser.permissions)
                    : data.originUser.permissions,
                },
              }
            : {}),
        },
        language: data.language,
        languagesId: data.languageObject?.id,
        locale: data.locale?.code,
        localesId: data.locale?.id,
        sessionRenewInProgress: false,
      };

    case 'LOAD_ENTITY':
      return {
        ...state,
        entityData: undefined,
        entityDataSource: action.payload.endpoint,
        entityDataLoading: true,
      };

    case 'LOAD_ENTITY_SUCCESS':
      // We must get only url of the entity without optional params
      const expectedUrl =
        state.entityDataSource && new URL(state.entityDataSource, 'http://modoc').pathname;
      const incomingUrl = new URL(action.payload.endpoint, 'http://modoc').pathname;

      // If the entity we are loading changed in the meantime, we no longer want to set it
      // For example, loading is slow and someone switched to different screen
      // We don't care about leading or trailing slashes
      const expected = expectedUrl?.replace(/(^\/+)|(\/+$)/g, '');
      const incoming = incomingUrl?.replace(/(^\/+)|(\/+$)/g, '');

      if (expected && expected !== incoming) return state;

      return {
        ...state,
        entityData: action.payload.response.data,
        entityDataLoading: false,
      };

    case 'LOAD_ENTITY_ERROR':
      return {
        ...state,
        entityDataSource: undefined,
        entityDataLoading: false,
      };

    case 'UNLOAD_ENTITY':
      return {
        ...state,
        entityData: undefined,
        entityDataSource: undefined,
        entityDataLoading: false,
      };

    case 'BEGIN_EDIT_ENTITY':
    case 'CREATE_ENTITY':
      return { ...state, entitySaveInProgress: true };

    case 'FINISH_EDIT_ENTITY':
    case 'CREATE_ENTITY_SUCCESS':
    case 'CREATE_ENTITY_ERROR':
      return { ...state, entitySaveInProgress: false };

    case 'SET_TAB_ERRORS':
      return { ...state, tabErrors: action.payload.errors };

    case 'SOCKET_ON':
      return { ...state, socket: { connected: true } };

    case 'SOCKET_OFF':
      return { ...state, socket: { connected: false } };

    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: action.payload };

    case 'SET_NOTIFICATIONS_COUNT':
      return { ...state, notifications: { ...state.notifications, unread: action.payload } };

    case 'UPDATE_AVAILABLE':
      return { ...state, updateAvailable: action.payload };

    case 'DATAGRID_SAVE_ENTITY_PARAMS':
      const { datagridParams } = action.payload;
      return {
        ...state,
        dataGridEntityParams: datagridParams,
      };

    case PREFIX_SAVE_VIEW:
      return {
        ...state,
        viewSavingInProgress: true,
      };

    case `${PREFIX_SAVE_VIEW}_SUCCESS` as SaveViewAction['type']:
    case `${PREFIX_SAVE_VIEW}_ERROR` as SaveViewAction['type']:
      return {
        ...state,
        viewSavingInProgress: false,
      };

    case PREFIX_DELETE_VIEW:
      return {
        ...state,
        viewDeletingInProgress: true,
      };

    case `${PREFIX_DELETE_VIEW}_SUCCESS` as DeleteViewAction['type']:
    case `${PREFIX_DELETE_VIEW}_ERROR` as DeleteViewAction['type']:
      return {
        ...state,
        viewDeletingInProgress: false,
      };

    case 'SET_OPEN_DROPDOWN_ID':
      const { id } = action.payload;
      return {
        ...state,
        openDropdownId: id,
      };

    // case 'SAVE_AVAILABILITY': TODO:
    case 'UPLOAD_IMAGE':
    case 'SEND_EMAIL':
    case 'BULK_DOWNLOAD':
    case 'BULK_UPLOAD_FILES':
    case 'SAVE_DIALOG_ENTITY':
      return {
        ...state,
        dialogSaveInProgress: true,
      };

    // case 'SAVE_AVAILABILITY_SUCCESS':
    // case 'SAVE_AVAILABILITY_ERROR':
    case 'UPLOAD_IMAGE_SUCCESS':
    case 'UPLOAD_IMAGE_ERROR':
    case 'BULK_DOWNLOAD_SUCCESS':
    case 'BULK_DOWNLOAD_ERROR':
    case 'SEND_EMAIL_SUCCESS':
    case 'SEND_EMAIL_ERROR':
    case `BULK_UPLOAD_FILES_SUCCESS`:
    case `BULK_UPLOAD_FILES_ERROR`:
    case `SAVE_DIALOG_ENTITY_SUCCESS`:
    case `SAVE_DIALOG_ENTITY_ERROR`:
      return {
        ...state,
        dialogSaveInProgress: false,
      };

    case 'API_QUERY':
      const prev = state.apiQueries[action.payload.urlOrId];

      if (!action.payload.urlOrId || (prev && isEqual(prev.options, action.payload.options))) {
        return state;
      }

      return {
        ...state,
        apiQueries: {
          ...state.apiQueries,
          [action.payload.urlOrId]: {
            ...prev,
            status: action.payload.options.autoload ? ApiQueryStatus.LOADING : ApiQueryStatus.IDLE,
            options: action.payload.options,
          },
        },
      };

    case 'FIRE_API_QUERY':
      const query = state.apiQueries[action.id];

      if (!query) {
        return state;
      }

      const resData = action.clear ? undefined : query.data;

      return {
        ...state,
        apiQueries: {
          ...state.apiQueries,
          [action.id]: {
            ...query,
            status: ApiQueryStatus.LOADING,
            isFired: true,
            data: resData,
            error: undefined,
          },
        },
      };

    case 'API_QUERY_RESULT':
      return {
        ...state,
        apiQueries: {
          ...state.apiQueries,
          [action.id]: { ...action.payload, isFired: false },
        },
      };

    case 'CLEAR_API_QUERY': {
      const apiQueries = { ...state.apiQueries };
      delete apiQueries[action.id];

      return {
        ...state,
        apiQueries,
      };
    }

    default:
      return state;
  }
}
